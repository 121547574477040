<template>
    <div class="dashboard" :style="`--var-radio-background-color: ${ habitTracking.public_settings.element_color ? habitTracking.public_settings.element_color : '#2f7eed' }; --var-element-text-color: ${ habitTracking.public_settings.element_text_color ? habitTracking.public_settings.element_text_color : '#2c3e50' }; --var-element-border-color: ${ habitTracking.public_settings.element_border_color ? habitTracking.public_settings.element_border_color : '#C0DAFF' }; --var-element-bg-color: ${ habitTracking.public_settings.element_bg_color ? habitTracking.public_settings.element_bg_color : '#f5f5f5' };`">
        <div class="client_card">
            <div v-show="habitTracking.public_settings.display_header == 1" class="card_header pointer"  @click="handleSetting('hdrsetting')" ref="hdrsection" :style="`background: ${habitTracking.public_settings.header_bgcolor}; color: ${habitTracking.public_settings.header_textcolor};`">
                <nav>
                    <!-- <h4>{{ habitTracking.public_settings.company_branding }}</h4> -->
                    <img class="logo_img" :src="habitTracking.public_settings.logo ? habitTracking.public_settings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a>
                        <h4>Dashboard</h4>
                    </a>
                </nav>
                <div class="user_box" @click="accountDrop = !accountDrop">
                    <img :src="habitTracking.public_settings.author ? habitTracking.public_settings.author : user.profile_pic" :alt="user.full_name">
                    <div class="user_info">
                        <h5 :style="`color: ${habitTracking.public_settings.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                    </div>
                    <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                        <li><i class="fas fa-home"></i> Primary Location</li>
                        <li><i class="far fa-user"></i> Profile Info</li>
                        <li><i class="far fa-file"></i> My Files</li>
                        <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                    </ul>
                </div>
            </div>
            <div class="card_body" :class="device != 'desk' ? ( device == 'tab' && habitTracking.public_settings.cover_type == 1 ? 'cover-image-md' : (habitTracking.public_settings.cover_type == 1 ? 'cover-image-md' : '')) :'cover-image'" @click="$parent.$parent && $parent.$parent.setting ? $parent.$parent.setting('cvrsetting') : $parent.setting('cvrsetting')" ref="cvrsection" v-show="habitTracking.public_settings && habitTracking.public_settings.display_cover && habitTracking.public_settings.has_small_cover">
                <picture v-if="(habitTracking.public_settings.cover_type == 2 || habitTracking.public_settings.cover_type == 3)">
                    <source v-if="habitTracking.public_settings.small_cover != null && habitTracking.public_settings.small_cover != '' && habitTracking.public_settings.small_cover != 'null'" :srcset="`${habitTracking.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="habitTracking.public_settings.cover ? habitTracking.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="habitTracking.public_settings.small_cover ? habitTracking.public_settings.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                </picture>
                <!-- <picture v-if="(habitTracking.public_settings.cover_type == 2 || habitTracking.public_settings.cover_type == 3)">
                    <source v-if="habitTracking.public_settings.has_small_cover && habitTracking.public_settings.small_cover != null && habitTracking.public_settings.small_cover != '' && habitTracking.public_settings.small_cover != 'null'" :srcset="`${habitTracking.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="habitTracking.public_settings.cover ? habitTracking.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="habitTracking.public_settings.has_small_cover && habitTracking.public_settings.small_cover ? habitTracking.public_settings.small_cover : (habitTracking.public_settings.cover ? habitTracking.public_settings.cover : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                </picture> -->
                <span v-if="habitTracking.public_settings.cover_type == 1" class="overlay" :style="`background-color: ${habitTracking.public_settings.cover_color ? habitTracking.public_settings.cover_color : habitTracking.public_settings.overlay_color};`"></span>
                <span v-else-if="habitTracking.public_settings.cover_type == 2 && habitTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${habitTracking.public_settings.overlay_color}; opacity: ${(habitTracking.public_settings.opac_overlay_color == 'transparent' || !habitTracking.public_settings.has_cover_overlay || habitTracking.public_settings.overlay_opacity == 0) ? '0': ((habitTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <span v-else-if="habitTracking.public_settings.cover_type == 3 && habitTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${habitTracking.public_settings.overlay_color}; opacity: ${(habitTracking.public_settings.opac_overlay_color == 'transparent' || !habitTracking.public_settings.has_cover_overlay || habitTracking.public_settings.overlay_opacity == 0) ? '0': ((habitTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <div class="banner_content" v-if="device == 'desk'">
                    <h4 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? habitTracking.headline_setting.font_size : ''}px; line-height: ${( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? parseInt(habitTracking.headline_setting.font_size) + 20 : ''}px; font-weight: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_weight}; font-family: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_family}; color: ${habitTracking.headline_setting.font_color}; ${ habitTracking.headline_setting && habitTracking.headline_setting.has_shadow ? 'text-shadow: ' + habitTracking.headline_setting.shadow_x +'px ' + habitTracking.headline_setting.shadow_y + 'px ' + habitTracking.headline_setting.shadow_blur + 'px ' + habitTracking.headline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.headline }}</h4>
                    <h1 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_size : ''}px; line-height: ${ habitTracking.subheadline_setting ? (parseInt(habitTracking.subheadline_setting.font_size) + 20) : ''}px; font-weight: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_weight : ''}; font-family: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_family : ''}; color: ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.font_color ? habitTracking.subheadline_setting.font_color : ''}; ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + habitTracking.subheadline_setting.shadow_x +'px ' + habitTracking.subheadline_setting.shadow_y + 'px ' + habitTracking.subheadline_setting.shadow_blur + 'px ' + habitTracking.subheadline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'tab'">
                    <h4 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? habitTracking.headline_setting.font_size / 2 : ''}px; line-height: ${( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? (parseInt(habitTracking.headline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_weight}; font-family: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_family}; color: ${habitTracking.headline_setting.font_color}; ${ habitTracking.headline_setting && habitTracking.headline_setting.has_shadow ? 'text-shadow: ' + habitTracking.headline_setting.shadow_x +'px ' + habitTracking.headline_setting.shadow_y + 'px ' + habitTracking.headline_setting.shadow_blur + 'px ' + habitTracking.headline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.headline }}</h4>
                    <h1 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_size / 1.5 : ''}px; line-height: ${ habitTracking.subheadline_setting ? (parseInt(habitTracking.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_weight : ''}; font-family: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_family : ''}; color: ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.font_color  ? habitTracking.subheadline_setting.font_color : ''}; ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + habitTracking.subheadline_setting.shadow_x +'px ' + habitTracking.subheadline_setting.shadow_y + 'px ' + habitTracking.subheadline_setting.shadow_blur + 'px ' + habitTracking.subheadline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'cell'">
                    <h4 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? (habitTracking.headline_setting.font_size / 3 < 20 ? 20 : habitTracking.headline_setting.font_size / 3) : ''}px; line-height: ${( habitTracking.headline_setting && habitTracking.headline_setting.font_size) ? (habitTracking.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(habitTracking.headline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_weight}; font-family: ${ habitTracking.headline_setting && habitTracking.headline_setting.font_family}; color: ${habitTracking.headline_setting.font_color}; ${ habitTracking.headline_setting && habitTracking.headline_setting.has_shadow ? 'text-shadow: ' + habitTracking.headline_setting.shadow_x +'px ' + habitTracking.headline_setting.shadow_y + 'px ' + habitTracking.headline_setting.shadow_blur + 'px ' + habitTracking.headline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.headline }}</h4>
                    <h1 v-if="habitTracking.public_settings.cover_type == 1 || habitTracking.public_settings.cover_type == 2" :style="`font-size: ${ habitTracking.subheadline_setting ? (habitTracking.subheadline_setting.font_size / 3 < 11 ? 11 : habitTracking.subheadline_setting.font_size / 3) : ''}px; line-height: ${ habitTracking.subheadline_setting ? (habitTracking.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(habitTracking.subheadline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_weight : ''}; font-family: ${ habitTracking.subheadline_setting ? habitTracking.subheadline_setting.font_family : ''}; color: ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.font_color  ? habitTracking.subheadline_setting.font_color : ''}; ${ habitTracking.subheadline_setting && habitTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + habitTracking.subheadline_setting.shadow_x +'px ' + habitTracking.subheadline_setting.shadow_y + 'px ' + habitTracking.subheadline_setting.shadow_blur + 'px ' + habitTracking.subheadline_setting.shadow_color : 'none'}`">{{ habitTracking.public_settings.subheadline }}</h1>
                </div>
            </div>
        </div>
        <div class="dashboard_content">
            <div class="form_area">
                <div class="section_item video_section p-0" :class="device == 'cell' ? 'mt-3' : 'mb-3'" ref="expvidsection" v-if="habitTracking.public_settings.display_explainer_video && ((habitTracking.public_settings.explainer_video && habitTracking.public_settings.explainer_video_type == 0) || (habitTracking.public_settings.explainer_embed && habitTracking.public_settings.explainer_video_type == 1))">
                    <div class="video_wpr">
                        <div v-if="habitTracking.public_settings.explainer_video_type == 0" v-html="parseEmbedCode(habitTracking.public_settings.explainer_video)"></div>
                        <div v-if="habitTracking.public_settings.explainer_video_type == 1" v-html="habitTracking.public_settings.explainer_embed"></div>
                    </div>
                </div>
                <div class="setting_wpr pt-4">
                    <div @click="handleSetting('catsetting')" ref="catsection">
                        <template v-for="(categorie, c) in habitTracking.categories" :key="c">
                            <template v-if="categorie.habits && categorie.habits.length">
                                <div class="cat_panel" :class="{ 'active' : habitTracking.data_settings.collapse_public_questions == 1 }">
                                    <h3 class="category_title" @click="accFunc($event)">
                                        {{ categorie.name ? categorie.name : 'Untitled Category'  }}
                                        <span class="show" :style="`color:${habitTracking.public_settings.element_color ? habitTracking.public_settings.element_color : '#2f7eed'}`">Show</span><span class="hide">Hide</span>
                                    </h3>
                                    <div class="quest_panel">
                                        <div class="quest_wpr" v-for="(habit, h) in categorie.habits" :key="h">
                                            <div class="habit_title"><label>{{h + 1}}</label><div class="title">{{ habit.description ? habit.description : 'Untitled Habit' }}</div></div>
                                            <div class="habit_details">
                                                <div class="require_wpr">
                                                    <p>{{ habit.requirements ? habit.requirements : 'No requirement available' }}</p>
                                                </div>
                                                <div class="grp_wpr" v-show="habitTracking.recurring_type == 2">
                                                    <label :for="`movement-${habit.id}`" class="yes">
                                                        <input type="radio" value="yes" :id="`movement-${habit.id}`" :name="`move-${habit.id}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label :for="`no_movement-${habit.id}`" class="no">
                                                        <input type="radio" value="no" :id="`no_movement-${habit.id}`" :name="`move-${habit.id}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                                <div v-show="habitTracking.recurring_type == 3">
                                                    <div class="success_goal"><i class="fas fa-trophy" :style="`color: ${ habitTracking.public_settings.element_color ? habitTracking.public_settings.element_color : '#2f7eed' };`"></i>{{ `Goal is ${ habit.success_goal > 1 ? habit.success_goal+' days' : habit.success_goal+' day' }` }} per week</div>
                                                    <ul class="days">
                                                        <li v-for="(day, d) in weekdays" :key="d">
                                                            <label :for="`${habit.id}day-${d}`" :class="day == 'Monday' ? 'active' : ''">
                                                                <input type="checkbox" :id="`${habit.id}day-${d}`" :true-value="1" :false-value="0" v-model="habitAnswers[day.toLowerCase().slice(0, 3)]" hidden>
                                                                <div class="item_wpr">
                                                                    <span class="weekdays">{{ day.slice(0, 3) }}</span>
                                                                    <span class="tick_box"></span>
                                                                </div>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    <!-- <div class="week_item" :class="{'Week_btn' : device == 'cell'}">
                                                        <button type="button" class="success_goal_btn" :style="`background-color: ${ habitTracking.public_settings.element_color ? habitTracking.public_settings.element_color : '' }; color: ${ habitTracking.public_settings.element_text_color ? habitTracking.public_settings.element_text_color : '' };`">{{ `Goal is ${ habit.success_goal > 1 ? habit.success_goal+' days' : habit.success_goal+' day' }` }}</button>
                                                    </div> -->
                                                </div>
                                                <div class="upload_image mt-2" v-show="habit.submit_proof == 1">
                                                    <image-upload image-type="all" upload-text="Image" v-model="logo[habit.id]"  :is-avatar="false" />
                                                </div>
                                                <div class="achieved_goal" v-if="habitTracking.recurring_type == 3 && (habit.success_goal <= Object.values(habitAnswers).filter(score => score == 1).length)">
                                                    <i class="fas fa-trophy" :style="`color: ${ habitTracking.public_settings.element_color ? habitTracking.public_settings.element_color : '#2f7eed' };`"></i> Goal Achieved
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="form_grp mb-4">
                        <div class="group_item">
                            <label class="input_label">Member ID</label>
                            <div class="field_wpr">
                                <input type="text" placeholder="ID goes here.." >
                            </div>
                        </div>
                    </div>
                    <div @click="handleSetting('subbtnsetting')" ref="subbtnsection">
                        <button type="button" class="primary_btn" :style="`background-color: ${ habitTracking.public_settings.submit_btn_bgcolor ? habitTracking.public_settings.submit_btn_bgcolor : '' }; color: ${ habitTracking.public_settings.submit_btn_textcolor ? habitTracking.public_settings.submit_btn_textcolor : '' };`">{{ habitTracking.public_settings.submit_btn_text ? habitTracking.public_settings.submit_btn_text : 'Submit' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <footer class="dashboard_footer" v-show="habitTracking.public_settings.display_footer == 1" @click="handleSetting('ftrsetting')" ref="ftrsection" :style="`background: ${habitTracking.public_settings.footer_bgcolor};`">
            <ul>
                <li class="pointer" v-show="habitTracking.public_settings.footer_has_facebook"><a :href="habitTracking.public_settings.footer_facebook" :style="`border-color: ${habitTracking.public_settings.footer_textcolor}; color: ${habitTracking.public_settings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer" v-show="habitTracking.public_settings.footer_has_twitter"><a :href="habitTracking.public_settings.footer_twitter" :style="`border-color: ${habitTracking.public_settings.footer_textcolor}; color: ${habitTracking.public_settings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                <li class="pointer" v-show="habitTracking.public_settings.footer_has_instagram"><a :href="habitTracking.public_settings.footer_instagram" :style="`border-color: ${habitTracking.public_settings.footer_textcolor}; color: ${habitTracking.public_settings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                <li class="pointer" v-show="habitTracking.public_settings.footer_has_linkedin"><a :href="habitTracking.public_settings.footer_linkedin" :style="`border-color: ${habitTracking.public_settings.footer_textcolor}; color: ${habitTracking.public_settings.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                <li class="pointer" v-show="habitTracking.public_settings.footer_has_youtube"><a :href="habitTracking.public_settings.footer_youtube" :style="`border-color: ${habitTracking.public_settings.footer_textcolor}; color: ${habitTracking.public_settings.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
            </ul>
            <h4 :style="`color: ${habitTracking.public_settings.footer_textcolor};`">{{ habitTracking.public_settings.footer_company ?  habitTracking.public_settings.footer_company : `© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}` }}</h4>
            <p>
                <a :href="habitTracking.public_settings.footer_terms" :style="`color: ${habitTracking.public_settings.footer_textcolor};`">Terms</a>
                <a :href="habitTracking.public_settings.footer_policy" :style="`color: ${habitTracking.public_settings.footer_textcolor};`">Privacy</a>
                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                    <a v-if="whiteLabel.has_link == 1" :style="`color: ${habitTracking.public_settings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    <a v-else :style="`color: ${habitTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                </template>
                <a v-else :style="`color: ${habitTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
            </p>
        </footer>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Habit Tracking Preview Content',

        data () {
            return {
                accountDrop: false,
                logo: [],
                weekdays: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],
                habitAnswers: {
                    mon: 0,
                    tue: 0,
                    wed: 0,
                    thu: 0,
                    fri: 0,
                    sat: 0,
                    sun: 0,
                },
                whiteLabel: {},
            }
        },

        props: {
            habitTracking: Object,
            device: {
                type: String,
                default: 'cell'
            },
            isNew: Boolean,
        },

        emit: [ 'update:modelValue' ],

        components: {
            ImageUpload
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
        },

        methods:{
            accFunc (e) {
                let el = e.currentTarget.closest('.cat_panel');
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        if (element) {
                            let top = element.offsetTop;
                            document.querySelector('.preview_content .content_area').scrollTo(0, top);
                        }
                    }, 100);
                }, 10);
            },

            handleSetting(val) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                if (vm.$parent.$parent && vm.$parent.$parent.setting) {
                    vm.$parent.$parent.setting(val);
                } else if (vm.$parent.setting) {
                    vm.$parent.setting(val);
                }
            }
        }
    }
</script>

<style scoped>
    .tab .dashboard_content {
        margin: 0;
    }

    .cell .dashboard_content {
        margin: 0;
        padding: 0 15px 15px 15px;
    }

    .category_title {
        font-size: 13px;
        line-height: 18px;
        border-radius: 12px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .quest_panel{
        margin-bottom: 40px;
        padding: 0 15px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #e9e9e9;
    }
    .quest_wpr{
        background: #fff;
        border-radius: 10px;
        margin: 25px 0;
        position: relative;
        padding: 15px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #e9e9e9;
    }
    .habit_title{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }
    .habit_title .title{
        flex: 1 1 auto;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: var(--var-element-text-color);
    }
    .habit_details {
        padding: 20px 0 0;
        display: none;
    }
    .cat_panel.active .quest_panel .quest_wpr .habit_details {
        display: block;
    }
    .habit_title label{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-radio-background-color);
        color: var(--var-radio-background-color);
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }
    .category_title span{
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #2f7eed;
        padding: 0 5px;
        line-height: 12px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .category_title span{
        color: #5a5a5a;
    }
    .category_title span.show, .cat_panel.active .category_title span.hide {
        display: block;
    }
    .category_title span.hide, .cat_panel.active .category_title span.show {
        display: none;
    }


    .require_wpr{
        padding: 0 0 5px 30px;
    }
    .require_wpr p{
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        color: #656565;
    }

    .require_wpr h4 {
        font-size: 16px;
        line-height: 20px;
        color: 32373b;
        font-weight: 500;
    }

    .tab .require_wpr h4 {
        font-size: 14px;
        line-height: 19px;
    }

    .client_card {
        width: 100%;
        border: 0;
    }

    .client_card .card_body h1 {
        font-size: 60px;
        line-height: 75px;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 15px;
    }

    .tab .client_card .card_body h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .client_card .card_body h4 {
        font-size: 28px;
        line-height: 38px;
        text-transform: capitalize;
        text-align: center;
        letter-spacing: 0;
        font-weight: 500;
    }

    .tab .client_card .card_body h4 {
        font-size: 18px;
        line-height: 25px;
    }

    .dashboard_footer {
        width: 100%;
        text-align: center;
    }

    .form_area {
        max-width: 800px;
        margin: 60px auto;
        flex: 1;
        border-radius: 12px;
    }
    .form_area .field_wpr{
        background: #fff;
    }

    .grp_wpr {
        display: flex;
        justify-content: center;
        gap: 8px;
        padding: 20px 30px 25px 30px;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }

    .picture_section {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -15px;
    }

    .picture_section>li {
        padding: 5px 15px;
        width: 33.333%;
    }

    .picture_section>li .section_wpr {
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        overflow: hidden;
    }

    .picture_section .section_wpr li .section_title {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
    }

    .picture_section .section_wpr li .section_title h4 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: left;
    }

    .picture_section .section_wpr li img {
        padding: 15px;
        width: 100%;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .section_wpr h3 {
        font-size: 20px;
        line-height: 28px;
    }

    h3.sub_header {
        font-size: 20px;
        line-height: 23px;
        margin: 30px 0 12px;
    }

    .tab .dashboard_content h3.sub_header {
        font-size: 18px;
        line-height: 21px;
        margin: 30px 0 12px;
    }

    .cell .dashboard_content h3.sub_header {
        font-size: 15px;
        line-height: 18px;
        margin: 30px 0 8px;
    }
    .days{
        border-top: 1px dashed #E4E7EC;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px 0;
        gap: 4px;
    }
    .days li label .item_wpr{
        width: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 8px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }
    .days li label span{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .days li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #cdcdcd;
        position: relative;
        margin-top: 7px;
    }
    .days li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: var(--var-radio-background-color);
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .days li label input{
        margin-right: 7px;
        opacity: 0;
    }
    .days li label input[type=checkbox]:checked ~ .item_wpr{
        background: #fbfbfb;
        border: 1px solid #d4d4d4;
    }
    .days li label input[type=checkbox]:checked ~ .item_wpr .tick_box:after{
        transform: scale(1);
    }
    .success_goal {
        display: flex;
        align-items: center;
        border-radius: 20px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        width: 100%;
        padding: 25px 0 25px 30px;
    }
    .success_goal i{
        color: #2f7eed;
        font-size: 12px;
        padding-right:10px;
    }
    .achieved_goal{
        font-size: 12px;
        line-height: 14px;
        border: 1px solid #d4d4d4;
        background: #fbfbfb;
        color: #121525;
        max-width: 150px;
        margin: 10px auto;
        padding: 10px 20px;
        border-radius: 20px;
        text-align: center;
        display: block;
    }
    .achieved_goal i{
        color: #2f7eed;
        padding-right: 5px;
    }

    .primary_btn {
        margin: 15px 0;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
    }
    /* .upload_image{
        padding: 0 10px;
    } */
    .upload_image :deep(.upload_wpr .upload_area){
        width: 100%;
        min-height: 120px;
        padding: 0;
        border-color: #d4d4d4;
        background-color: #fbfbfb;
    }
    .upload_image :deep(.upload_wpr .upload_area img:not(.uploaded_img)){
        max-width: 50px;
    }
    .upload_image :deep(.upload_wpr .upload_area h4){
        font-size: 11px;
        line-height: 15px;
    }

    /* Page Preview */
    .cell .dashboard {
        margin: 0;
    }

    .cell .client_card {
        border-radius: 6px;
    }

    .cell .client_card .card_header {
        padding: 15px 20px;
    }

    .cell .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .cell .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }

    .cell .client_card .card_body {
        text-align: center;
    }

    .cell .client_card .card_body .banner_content {
        padding: 0 15px;
    }

    .cell .client_card .card_body h1 {
        font-size: 17px;
        line-height: 30px;
        margin: 0;
        font-weight: 600;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
        /* letter-spacing: 1px; */
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body h4 {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        /* letter-spacing: 0; */
        text-transform: initial;
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .cell .form_area {
        max-width: 400px;
        margin: 40px auto;
        border-radius: 6px;
    }

    .cell .form_grp {
        padding: 10px 0;
    }

    .cell .setting_wpr .group_item .input_label {
        font-size: 13px;
        line-height: 17px;
    }

    .cell .field_wpr input {
        height: 45px;
        font-size: 13px;
    }

    .cell .field_wpr textarea {
        height: 52px;
        font-size: 11px;
        padding: 5px 10px;
    }

    .cell .field_wpr.has_suffix .suffix {
        font-size: 10px;
    }
    .cell .require_wpr{
        border-radius: 4px;
    }
    .cell .require_wpr h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .cell h3.sub_header {
        font-size: 16px;
        line-height: 20px;
        margin: 20px 0 10px;
    }

    .cell .day_sorting li {
        min-width: 75px;
    }

    .cell .day_sorting li label {
        padding: 5px 10px;
    }

    .cell .day_sorting li label p {
        font-size: 10px;
    }

    .cell .goal_btn {
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
        border-radius: 4px;
    }

    .cell .goal_btn i {
        font-size: 14px;
    }

    .cell .day_sorting li label span {
        transform: scale(0.8);
    }

    .cell .upload_image label {
        padding: 10px;
    }

    .cell .upload_image .icon {
        max-height: 40px;
        width: auto;
    }

    .cell .upload_image h4 {
        font-size: 10px;
        line-height: 12px;
        margin-top: 7px;
    }

    .cell .primary_btn {
        height: 55px;
        letter-spacing: 1px;
        font-size: 16px;
        margin: 30px 0 15px;
    }

    :deep(.video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    :deep(.tab_content) {
        height: 100%;
    }



    .week_item {
        flex: 0 0 30%;
        padding: 5px 0;
    }

    .cell .week_item {
        flex: auto;
        padding: 0px 2px;
    }

    .week_item.Week_btn {
        flex-basis: 100%;
    }



    .form_grp .week_item input[type='checkbox'] {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        outline: none;
        border: 2px solid var(--var-radio-background-color);
    }

    .form_grp .week_item input[type='checkbox']:before {
        content: '';
        display: block;
        width: 80%;
        height: 80%;
        margin: 10%;
        border-radius: 50%;
    }

    .form_grp .week_item input[type="checkbox"]:checked:before {
        background: var(--var-radio-background-color);
    }

    .form_grp .week_item input[type="checkbox"]:checked {
        border-color: var(--var-radio-background-color);
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .client_card .card_header .user_box {
        margin-left: auto;
    }
    .video_section{
        margin-bottom: 40px;
    }
</style>
